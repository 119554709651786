import { Component, Vue, Getter } from "nuxt-property-decorator";
import { MainMenu } from "~/types/components";

@Component
export default class ExtraFuncionsMenuLinks extends Vue {
  @Getter("tenant/ivgId") ivgId!: string;
  public hasModalForm: boolean = false;
  public modalForm: any = { visible: false, title: "" };

  public checkIfAction(url: string): void {
    if (url.includes("#prenotaVisita#")) {
      this.modalForm.visible = true;
    }
    if (url.includes("#aggiudicazioni#")) {
      this.latestAddedFilter();
    }
  }

  public checkIfHasModalForm(menu: MainMenu[]) {
    const prenotaVisita = menu.find((item) => item?.link?.includes("#prenotaVisita#"));
    this.hasModalForm = prenotaVisita ? true : false;
  }

  public async latestAddedFilter() {
    const today = this.$dayjs().subtract(1, "day").startOf("day").unix();
    const daysAgo = this.$dayjs().subtract(15, "day").startOf("day").unix();

    this.$store.commit("filter/resetFilterMutation", [this.ivgId]);

    this.$store.commit("filter/setDataVenditaSearch", [daysAgo, today]);
    this.$store.commit("filter/setStatusFilter", ["Venduto"]);
    this.$store.commit("filter/setVisibileSuFilter", [this.ivgId]);
    this.$store.commit("filter/setGenreFilter", []);

    await this.$store.dispatch("paginator/resetPage");
    await this.$store.dispatch("filter/setQueryParams");
    this.$store.commit("typesense/clearResults");
    await this.$store.dispatch("typesense/getData");
  }
}
