export default class FormBookingSpecial {
  nome: string = "";
  cognome: string = "";
  numero_procedura: string = "";
  numero_lotto: string = "";
  data_vendita: string = "";
  ragione_sociale: string = "";
  tipo_persona: string = "";
  indirizzo: string = "";
  civico: string = "";
  cap: string = "";
  citta: string = "";
  provincia: string = "";
  telefono: string = "";
  fax: string = "";
  email: string = "";
  ivg_competenza: number | null = null;
  data_nascita: string = "";
  luogo_nascita: string = "";
  tipo_documento: string = "";
  numero_documento: string = "";
  accompagnatore: string = "";
  accettazione_privacy?: boolean = false;
  recaptcha?: string = "";
  allegato_documento?: File | undefined = undefined;
}
