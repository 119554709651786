
import ExtraFuncionsMenuLinks from "~/mixins/extramenulink";
import { Component, Prop, Getter } from "nuxt-property-decorator";
import { FooterData } from "~/types/components";
import { BIcon, BIconFacebook, BIconLinkedin, BIconTwitter, BIconYoutube } from "bootstrap-vue";

@Component({ components: { BIcon, BIconFacebook, BIconLinkedin, BIconTwitter, BIconYoutube } })
export default class TheFooterMaster extends ExtraFuncionsMenuLinks {
  @Prop() footerdata!: FooterData;
  @Getter("preset/allGenres") genres: any;

  mounted() {
    this.checkIfHasModalForm(this.footerdata.footerMenu);
  }

  public SOCIALS(): Array<any> {
    let arr: Array<any> = [];
    Object.keys(this.footerdata.socials).forEach((e) => {
      if (this.footerdata.socials[e]) {
        arr.push({ name: e, link: this.footerdata.socials[e] });
      }
    });
    return arr;
  }

  closeFormModal() {
    this.modalForm.visible = false;
  }
}
