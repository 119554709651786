import { Component } from "nuxt-property-decorator";
import ResponseHandler from "~/helpers/validations/ResponseHandler";
import { Validations } from "vuelidate-property-decorators";
import {
  required,
  email,
  sameAs, // @ts-ignore
} from "vuelidate/lib/validators";

@Component({})
export default class BookingSpecialValidator extends ResponseHandler {
  @Validations()
  validations = {
    form: {
      nome: { required },
      cognome: { required },
      tipo_persona: { required },
      indirizzo: { required },
      civico: { required },
      cap: { required },
      citta: { required },
      provincia: { required },
      telefono: { required },
      email: { required, email },
      data_nascita: { required },
      data_vendita: { required },
      luogo_nascita: { required },
      tipo_documento: { required },
      numero_documento: { required },
      numero_procedura: { required },
      ivg_competenza: { required },
      accettazione_privacy: { sameAs: sameAs(() => true) },
      ragione_sociale: {},
      fax: {},
      numero_lotto: {},
      accompagnatore: {},
      allegato_documento: {},
    },
  };
}
