
import { Component, Getter } from "nuxt-property-decorator";
import BookingSpecialValidator from "@/helpers/validations/bookingSpecial";
import FormBookingSpecial from "~/types/forms/FormBookingSpecial";
import { IvgIdentity } from "~/types/components";
import { saveConsent } from "@/types/forms/saveConsent";

@Component
export default class BookingSpecialForm extends BookingSpecialValidator {
  @Getter("tenant/privacy") privacy!: any;
  public form: FormBookingSpecial = new FormBookingSpecial();
  public loading: boolean = false;
  @Getter("tenant/ivgIdentity") ivg!: IvgIdentity;

  public selectedPersona: any = {};
  public selectedIvg: any = {};
  public options: Array<any> = [
    { text: "Persona Giuridica", value: "Persona Giuridica" },
    { text: "Persona Fisica", value: "Persona Fisica" },
  ];

  public optionsIvg: Array<any> = [
    { text: "", value: null },
    { text: "ISVEG Firenze", value: 16 },
    { text: "ISVEG Pistoia", value: 17 },
    { text: "ISVEG Prato", value: 18 },
    { text: "ISVEG Srl", value: 8 },
  ];

  public optionsDocument: Array<any> = [
    { text: "", value: null },
    { text: "Carta di identità", value: "Carta di identità" },
    { text: "Patente di Guida", value: "Patente di Guida" },
    { text: "Passaporto", value: "Passaporto" },
  ];

  putFileInModel(event: File) {
    this.$v.form.allegato_documento.$model = event;
  }

  async mounted() {
    try {
      await this.$recaptcha.init();
    } catch (e) {
      console.error(e);
    }
  }

  public async onSubmit() {
    this.loading = true;
    this.$v.form.$touch();

    if (this.$v.$invalid) {
      return;
    }

    const formData = new FormData();
    for (const [key, value] of Object.entries(this.form)) {
      if (key === "recaptcha") {
      } else if (key === "accettazione_privacy") {
        formData.append(key, "1");
      } else {
        formData.append(key, value === undefined ? "" : value);
      }
    }

    try {
      const token = await this.$recaptcha.execute("prenotazioneVisitaGenerico");
      formData.append("recaptcha", token);
    } catch (error) {
      throw error;
    }
    try {
      await this.$axios.$post(this.$config.astaEndpoint + "forms/prenotazioneVisitaGenerico", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // invio i dati consent a iubenda
      saveConsent(
        "booking-special-form",
        "booking-special-submit",
        { first_name: "nome", last_name: "cognome", email: "email" },
        {
          legal_documents: "accettazione_privacy",
        },
        [{ identifier: "privacy_policy", version: "15/05/2018" }]
      );

      this.success = true;
      this.$emit("close-modal");
      this.resetForm();
    } catch (error: any) {
      console.error(error);
    }
  }

  public resetForm() {
    this.form = new FormBookingSpecial();
    setTimeout(() => {
      this.$v.$reset();
    }, 10);
  }
}
